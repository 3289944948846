import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import './CardList.css';
import Popup from './Popup';
import SkuDetailsPopup from './SkuDetailsPopup';
import { useTranslation } from 'react-i18next';

const CardList = ({ cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSkus, calculatePrice, exchangeRates }) => {
  const { t } = useTranslation();
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedPrintings, setSelectedPrintings] = useState({});
  const [quantities, setQuantities] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showSkuDetailsPopup, setShowSkuDetailsPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [prices, setPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isDKeyPressed = useRef(false);

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices = {};
      for (const card of cards) {
        const cardConditions = [...new Set(card.skus
          .map(sku => sku.condAbbr)
          .filter(cond => allowedConditions.includes(cond)))];
        const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
        const condition = selectedConditions[card._id] || cardConditions[0] || '';
        const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
        const price = calculatePrice(card, condition, printing);
        newPrices[card._id] = price;
      }
      setPrices(newPrices);
      setIsLoading(false);
    };

    fetchPrices();
  }, [cards, selectedConditions, selectedPrintings, allowedConditions, calculatePrice]);

  const handleConditionChange = useCallback((cardId, newCondition) => {
    setSelectedConditions(prev => ({ ...prev, [cardId]: newCondition }));
  }, []);

  const handlePrintingChange = useCallback((cardId, newPrinting) => {
    setSelectedPrintings(prev => ({ ...prev, [cardId]: newPrinting }));
  }, []);

  const handleAddToCart = useCallback((card, isCash) => {
    const condition = selectedConditions[card._id] || card.skus[0]?.condAbbr || '';
    const printing = selectedPrintings[card._id] || card.skus[0]?.printingName || '';
    const quantity = quantities[card._id] || 1;
    const sku = card.skus.find(s => s.condAbbr === condition && s.printingName === printing);
    addToCart(card, condition, printing, quantity, sku?.lowPrice, sku?.skuId, isCash);
  }, [selectedConditions, selectedPrintings, quantities, addToCart]);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const handleOpenSkuDetails = useCallback((card) => {
    if (isDKeyPressed.current) {
      setSelectedCard(card);
      setShowSkuDetailsPopup(true);
    }
  }, []);

  const closeSkuDetailsPopup = useCallback(() => {
    setShowSkuDetailsPopup(false);
    setSelectedCard(null);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'd' || e.key === 'D') {
        isDKeyPressed.current = true;
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'd' || e.key === 'D') {
        isDKeyPressed.current = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const isExcluded = useCallback((card, condition, printing) => {
    const sku = card.skus.find(s => s.condAbbr === condition && s.printingName === printing);
    return sku && excludedSkus.includes(sku.skuId.toString());
  }, [excludedSkus]);

  const formatPrice = (price) => {
    return isNaN(price) ? '0.00' : price.toFixed(2);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <p>{t('Just fetching you the best prices...')}</p>
      </div>
    );
  }

  return (
    <div className="card-list">
      {cards.map((card) => {
        const cardConditions = [...new Set(card.skus
          .map(sku => sku.condAbbr)
          .filter(cond => allowedConditions.includes(cond)))];
        const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
        const condition = selectedConditions[card._id] || cardConditions[0] || '';
        const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
        const { cashPrice, creditPrice, isBulk, timestamp } = prices[card._id] || { cashPrice: 0, creditPrice: 0, isBulk: false, timestamp: null };
        const excluded = isExcluded(card, condition, printing);
        const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;

        return (
          <div key={card._id} className="card">
            <img src={card.image} alt={card.name} className="card-image" />
            <div className="card-info">
              <h3 className="card-name">{card.name}</h3>
              <p className="card-expansion">{card.expansionName || t('Expansion not available')}</p>
              <div className="card-options">
                <select 
                  className="condition-select"
                  value={condition}
                  onChange={(e) => handleConditionChange(card._id, e.target.value)}
                >
                  {cardConditions.map((cond, index) => (
                    <option key={index} value={cond}>{cond}</option>
                  ))}
                </select>
                <select 
                  className="printing-select"
                  value={printing}
                  onChange={(e) => handlePrintingChange(card._id, e.target.value)}
                >
                  {uniquePrintings.map((printing, index) => (
                    <option key={index} value={printing}>{printing}</option>
                  ))}
                </select>
              </div>
              <div className="card-actions">
                {excluded || noPriceAvailable ? (
                  <button className="add-to-cart-button not-buying" disabled>
                    {t('Not Buying')}
                  </button>
                ) : isBulk ? (
                  <button className="add-to-cart-button bulk" onClick={() => setShowPopup(true)}>
                    {t('Bulk Item')}
                  </button>
                ) : (
                  <>
                    <button 
                      className="add-to-cart-button credit"
                      onClick={() => handleAddToCart(card, false)}
                    >
                      {t('Credit')} {currencySymbols[userSettings.currency] || '$'}{formatPrice(creditPrice)}
                    </button>
                    <button 
                      className="add-to-cart-button cash"
                      onClick={() => handleAddToCart(card, true)}
                    >
                      {t('Cash')} {currencySymbols[userSettings.currency] || '$'}{formatPrice(cashPrice)}
                    </button>
                  </>
                )}
              </div>
              {timestamp && (
                <p className="price-timestamp" onClick={() => handleOpenSkuDetails(card)}>
                  {t('Price fetched at')}: {formatTimestamp(timestamp)}
                </p>
              )}
            </div>
          </div>
        );
      })}
      {showPopup && <Popup content={bulkSettings} onClose={closePopup} />}
      {showSkuDetailsPopup && selectedCard && (
        <SkuDetailsPopup
          card={selectedCard}
          onClose={closeSkuDetailsPopup}
          calculatePrice={calculatePrice}
          userSettings={userSettings}
          exchangeRates={exchangeRates}
        />
      )}
    </div>
  );
};

export default React.memo(CardList);
