import React from 'react';
import './LanguagePopup.css';

const flags = {
  en: `${process.env.PUBLIC_URL}/flags/en.png`,
  es: `${process.env.PUBLIC_URL}/flags/es.png`,
  fr: `${process.env.PUBLIC_URL}/flags/fr.png`,
  de: `${process.env.PUBLIC_URL}/flags/de.png`,
  pl: `${process.env.PUBLIC_URL}/flags/pl.png`
};

const LanguagePopup = ({ onSelectLanguage }) => {
  return (
    <div className="language-popup-overlay">
      <div className="language-popup">
        <h2>Select Your Language</h2>
        <div className="language-options">
          {Object.entries(flags).map(([lang, flagSrc]) => (
            <button key={lang} onClick={() => onSelectLanguage(lang)} className="language-option">
              <img src={flagSrc} alt={`${lang} flag`} className="flag-image" />
              <span className="language-name">{lang.toUpperCase()}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguagePopup;