import React from 'react';
import './Cart.css';

const Cart = ({ cartItems, removeFromCart, checkout, updateQuantity, togglePaymentType, openEmailValuationForm, userSettings, clearCart }) => {
  const cashSubtotal = cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice)) * item.quantity, 0);
  const creditSubtotal = cartItems.reduce((sum, item) => sum + (!item.isCash ? parseFloat(item.creditPrice) : parseFloat(item.cashPrice)) * item.quantity, 0);
  const total = cartItems.reduce((sum, item) => sum + (item.isCash ? parseFloat(item.cashPrice) : parseFloat(item.creditPrice)) * item.quantity, 0);

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency || 'USD'] || '$';

  return (
    <div className="cart">
      <h2 className="cart-title">Your Cart</h2>
      {cartItems.length === 0 ? (
        <p className="cart-empty">Your cart is empty</p>
      ) : (
        <>
          <ul className="cart-items">
            {cartItems.map((item, index) => (
              <li key={`${item._id}-${item.condition}-${item.printing}-${index}`} className="cart-item">
                <div className="item-details">
                  <span className="item-name">{item.name}</span>
                  <span className="item-price">
                    {currencySymbol}{item.isCash ? item.cashPrice : item.creditPrice} {item.isCash ? 'Cash' : 'Credit'}
                  </span>
                </div>
                <div className="item-actions">
                  <div className="quantity-control">
                    <button 
                      onClick={() => updateQuantity(item, item.quantity - 1)}
                      disabled={item.quantity <= 1}
                      className="quantity-button"
                    >
                      -
                    </button>
                    <span className="item-quantity">{item.quantity}</span>
                    <button 
                      onClick={() => updateQuantity(item, item.quantity + 1)}
                      disabled={item.quantity >= 4}
                      className="quantity-button"
                    >
                      +
                    </button>
                  </div>
                  <button 
                    className="toggle-payment-button"
                    onClick={() => togglePaymentType(item)}
                  >
                    Switch to {item.isCash ? 'Credit' : 'Cash'}
                  </button>
                  <button 
                    className="remove-button"
                    onClick={() => removeFromCart(item)}
                    aria-label={`Remove ${item.name} from cart`}
                  >
                    &times;
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="cart-summary">
            <div className="subtotal">
              <span>Cash Subtotal:</span>
              <span>{currencySymbol}{cashSubtotal.toFixed(2)}</span>
            </div>
            <div className="subtotal">
              <span>Credit Subtotal:</span>
              <span>{currencySymbol}{creditSubtotal.toFixed(2)}</span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>{currencySymbol}{total.toFixed(2)}</span>
            </div>
          </div>
          <button 
            className="checkout-button"
            onClick={checkout}
          >
            Proceed to Checkout
          </button>
          <button 
            className="clear-cart-button"
            onClick={clearCart}
          >
            Clear All
          </button>
        </>
      )}
    </div>
  );
};

export default Cart;
