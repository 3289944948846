import React, { useEffect } from 'react';
import './OrderCompletionPopup.css';

const OrderCompletionPopup = ({ onClose, isProcessing, orderCompleted, onResetApp }) => {
  useEffect(() => {
    // Disable scrolling on the body when the popup is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling when the component unmounts
      document.body.style.overflow = 'unset';
    };
  }, []);

  const renderContent = () => {
    if (isProcessing) {
      return (
        <>
          <h2>Processing Order</h2>
          <p>Please wait while we process your order. This may take a few moments.</p>
          <div className="loading-spinner"></div>
        </>
      );
    } else if (orderCompleted) {
      return (
        <>
          <h2>Order Received</h2>
          <p>Thank you for your order! It has been successfully received.</p>
          <p>A confirmation email has been sent to your email address.</p>
          <p className="important-notice">
            <strong>Important:</strong> Please do not send any items at this time. 
            Await further emails for instructions on packaging and shipping.
          </p>
          <button onClick={() => { onClose(); onResetApp(); }}>Close</button>
        </>
      );
    } else {
      return (
        <>
          <h2>Error</h2>
          <p>There was an error processing your order. Please try again or contact support.</p>
          <button onClick={onClose}>Close</button>
        </>
      );
    }
  };

  return (
    <div className="order-completion-overlay">
      <div className="order-completion-popup">
        {renderContent()}
      </div>
    </div>
  );
};

export default OrderCompletionPopup;
