import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, Sliders, ShoppingCart, ClipboardList, CreditCard, Mail, HelpCircle } from 'lucide-react';
import './Infographics.css';

const InfoCard = ({ icon: Icon, title, content }) => (
  <div className="info-card">
    <div className="info-card-header">
      <div className="info-card-icon">
        <Icon size={24} />
      </div>
      <h3>{title}</h3>
    </div>
    <p>{content}</p>
  </div>
);

const Infographics = () => {
  const { t } = useTranslation();

  return (
    <div className="infographics">
      <div className="info-grid">
        <InfoCard
          icon={Search}
          title={t('1. Search for Cards')}
          content={t('Use the search bar to find specific cards or browse through our catalog.')}
        />
        
        <InfoCard
          icon={Sliders}
          title={t('2. Select Condition and Printing')}
          content={t('Choose the condition and printing of your card from the dropdown menus.')}
        />
        
        <InfoCard
          icon={ShoppingCart}
          title={t('3. Add to Cart')}
          content={t('Click on either the Cash or Credit button to add the card to your cart.')}
        />
        
        <InfoCard
          icon={ClipboardList}
          title={t('4. Review Your Cart')}
          content={t('Check your cart to review the items you\'ve added and make any necessary adjustments.')}
        />
        
        <InfoCard
          icon={CreditCard}
          title={t('5. Checkout')}
          content={t('When you\'re ready, proceed to checkout to complete your buylist submission.')}
        />

        <InfoCard
          icon={Mail}
          title={t('6. Wait for Next Steps')}
          content={t('After checkout, sit back and wait for an email with the next steps and shipping instructions.')}
        />
      </div>
      
      <div className="tips-section">
        <div className="tips-header">
          <HelpCircle size={24} />
          <h3>{t('Tips')}</h3>
        </div>
        <ul>
          <li>{t('We use live TCGplayer pricing to calculate our buy rates, ensuring you get the very best and most up-to-date prices.')}</li>
          <li>{t('Use the image upload feature for quick card recognition.')}</li>
          <li>{t('Button colors: Green for buying, Red for not buying, and Grey for bulk items.')}</li>
          <li>{t('Credit offers are typically higher than cash offers.')}</li>
        </ul>
      </div>
    </div>
  );
};

export default Infographics;