import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ImageUpload.css';

const ImageUpload = ({ onImageRecognized }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files).slice(0, 5); // Limit to 5 files
    if (files.length === 0) return;

    setIsLoading(true);

    try {
      const recognizedProductIds = [];

      const base64Images = await Promise.all(files.map(file => convertToBase64(file)));

      const responses = await Promise.all(base64Images.map(base64Image =>
        fetch('https://api.ximilar.com/collectibles/v2/card_id', {
          method: 'POST',
          headers: {
            'Authorization': 'Token 5247b7f9456e965d3dbeb13f35ac49633e632721',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            records: [
              {
                _base64: base64Image
              }
            ]
          })
        })
      ));

      for (const response of responses) {
        if (!response.ok) {
          throw new Error(`Image recognition failed: ${response.statusText}`);
        }

        const responseData = await response.json();
        console.log("API Response:", responseData);

        const bestMatch = responseData.records?.[0]?._objects?.[0]?._identification?.best_match;
        if (bestMatch && bestMatch.links && bestMatch.links['tcgplayer.com']) {
          const tcgPlayerUrl = bestMatch.links['tcgplayer.com'];
          const productId = tcgPlayerUrl.split('/').pop();
          if (productId) {
            recognizedProductIds.push(productId);
          }
        } else {
          console.log("No product ID found for an image");
        }
      }

      onImageRecognized(recognizedProductIds);
    } catch (error) {
      console.error('Error during image recognition:', error);
      alert(t('Image recognition failed. Please try again. Error: ' + error.message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="image-upload">
      <label htmlFor="file-upload" className="custom-file-upload">
        <i className="fas fa-camera"></i> {t('Add Cards by Image')}
      </label>
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        multiple
        capture="camera"
        onChange={handleImageUpload}
        disabled={isLoading}
      />
      {isLoading && <p className="loading-text">{t('Recognizing images...')}</p>}
    </div>
  );
};

export default ImageUpload;
