import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true
});

async function openAITranslate(text, sourceLang, targetLang) {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are a translator. Translate the given text from ${sourceLang} to ${targetLang}. Only provide the translation, no additional text.`
        },
        {
          role: "user",
          content: text
        }
      ],
    });

    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error("Error translating text with OpenAI:", error);
    return null;
  }
}

function googleTranslate(text, sourceLang, targetLang) {
  const encodedText = encodeURIComponent(text);
  const url = `https://translate.google.com/translate_a/single?client=at&dt=t&dt=ld&dt=qca&dt=rm&dt=bd&dj=1&hl=${targetLang}&ie=UTF-8&oe=UTF-8&inputm=2&otf=2&iid=1dd3b944-fa62-4b55-b330-74909a99969e&sl=${sourceLang}&tl=${targetLang}&q=${encodedText}`;

  return fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.sentences && data.sentences.length > 0) {
        return data.sentences.map(s => s.trans).join(' ');
      }
      throw new Error('No translation found');
    })
    .catch(error => {
      console.error("Error translating text with Google:", error);
      return null;
    });
}

export async function translateText(text, sourceLang, targetLang = 'en') {
  // Try OpenAI first
  const openAIResult = await openAITranslate(text, sourceLang, targetLang);
  if (openAIResult) {
    return openAIResult;
  }

  // Fallback to Google Translate
  const googleResult = await googleTranslate(text, sourceLang, targetLang);
  if (googleResult) {
    return googleResult;
  }

  // If both fail, return the original text
  console.warn("Both translation methods failed. Returning original text.");
  return text;
}

export function isTranslationAvailable() {
  return true; // Always return true since we have a fallback method
}