import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CardList from './components/CardList';
import Cart from './components/Cart';
import CheckoutPage from './components/CheckoutPage';
import LanguagePopup from './components/LanguagePopup';
import ImageUpload from './components/ImageUpload';
import Infographics from './components/Infographics';
import EnquiryPopup from './components/EnquiryPopup';
import { translateText, isTranslationAvailable } from './utils/openaiTranslate';
import { BACKEND_URL } from './config';
import './App.css';

console.log('Backend URL:', BACKEND_URL);

const DEFAULT_USER_SETTINGS = {
  conditions: ['NM', 'LP', 'MP', 'HP'],
  defaultCashPercentage: '50',
  defaultCreditPercentage: '60',
  enabledGames: ['Magic: The Gathering'],
  excludedSku: [],
  floorPrice: '0',
  bulkSettings: '',
  box1: '',
  currency: 'USD',
};

// Cache for storing fetched prices
const priceCache = new Map();

// Cache for storing games and sets
const gamesCache = new Map();
const setsCache = new Map();

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please refresh the page.</h1>;
    }

    return this.props.children;
  }
}

const App = () => {
  const { t, i18n } = useTranslation();
  const [cards, setCards] = useState([]);
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem('cartItems');
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [userSettings, setUserSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [enabledGames, setEnabledGames] = useState([]);
  const [allowedConditions, setAllowedConditions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [expansionNames, setExpansionNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchAbbr, setSearchAbbr] = useState('');  // New state for abbreviation search
  const [searchNumber, setSearchNumber] = useState(''); // New state for number search
  const [floorPrice, setFloorPrice] = useState(0);
  const [bulkSettings, setBulkSettings] = useState('');
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [username, setUsername] = useState('');
  const [box1Content, setBox1Content] = useState('');
  const [showLanguagePopup, setShowLanguagePopup] = useState(true);
  const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);
  const [recognizedCard, setRecognizedCard] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [excludedSkus, setExcludedSkus] = useState([]);
  const [exchangeRates, setExchangeRates] = useState(null);
  const [userCurrency, setUserCurrency] = useState('USD');
  const [searchError, setSearchError] = useState(null);

  const [searchParams, setSearchParams] = useState({
    term: '',
    abbr: '',  // New parameter for abbreviation
    number: '', // New parameter for number
    game: 'Magic: The Gathering',
    set: '',
    page: 1
  });
  const isFetchingRef = useRef(false);

  // Helper function to normalize number
  const normalizeNumber = (number) => {
    if (!number) return '';
    // Remove leading zeros and anything after special characters
    return number.replace(/^0+/, '').split(/[^0-9]/)[0];
  };

  const handleOpenEnquiryPopup = useCallback(() => {
    setShowEnquiryPopup(true);
  }, []);

  const handleCloseEnquiryPopup = useCallback(() => {
    setShowEnquiryPopup(false);
  }, []);

  const handleContinueEnquiry = useCallback(() => {
    setShowEnquiryPopup(false);
    if (userSettings && userSettings.email) {
      const subject = encodeURIComponent('Please assess my cards');
      const body = encodeURIComponent('I would like to request an assessment of my cards.');
      window.open(`mailto:${userSettings.email}?subject=${subject}&body=${body}`, '_blank');
    }
  }, [userSettings]);

  useEffect(() => {
    const path = window.location.pathname;
    const extractedUsername = path.split('/')[1];
    setUsername(extractedUsername || 'CapeFearCollectibles');
  }, []);

  useEffect(() => {
    if (username) {
      fetchUserSettings().catch(err => {
        console.error("Error fetching user settings:", err);
        setError("Failed to load user settings. Please refresh the page.");
      });
    }
  }, [username]);

  const fetchExchangeRates = useCallback(() => {
    const hardcodedRates = {
      CAD: 1.350255,
      GBP: 0.753671,
      AUD: 1.452544,
      NZD: 1.607852,
      MXN: 19.415903,
      USD: 1 // Always include USD with a rate of 1
    };
    setExchangeRates(hardcodedRates);
  }, []);

  useEffect(() => {
    fetchExchangeRates();
  }, [fetchExchangeRates]);

  const fetchCatalog = useCallback(async (params) => {
    if (isFetchingRef.current) {
      console.log('Fetch already in progress, skipping');
      return;
    }

    isFetchingRef.current = true;
    setIsLoading(true);
    setSearchError(null);
    console.log('Fetching catalog with params:', params);

    try {
      const url = new URL(`${BACKEND_URL}/catalog`);
      url.search = new URLSearchParams({
        game: params.game,
        page: params.page.toString(),
        limit: itemsPerPage.toString(),
        set: params.set,
        search: params.term,
        abbr: params.abbr,    // Add abbreviation parameter
        number: params.number  // Add number parameter
      }).toString();

      console.log('Fetching from URL:', url.toString());

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Received data:', data);

      if (data.items.length === 0) {
        setSearchError(t('No results found. Please check your spelling and try again.'));
      }

      setCards(data.items || []);
      setTotalPages(data.totalPages || 1);
      setExpansionNames(data.expansionNames || []);

    } catch (error) {
      console.error('Error fetching catalog:', error);
      setSearchError(t('No results found. Please check your spelling and try again.'));
    } finally {
      setIsLoading(false);
      isFetchingRef.current = false;
    }
  }, [itemsPerPage, t]);

  const debouncedFetchCatalog = useMemo(
    () => debounce((params) => {
      if (!isInitialLoad && userSettings) {
        fetchCatalog(params);
      }
    }, 300),
    [fetchCatalog, isInitialLoad, userSettings]
  );

  useEffect(() => {
    console.log('Search params changed:', searchParams);
    if (!isInitialLoad) {
      debouncedFetchCatalog(searchParams);
    }
  }, [searchParams, debouncedFetchCatalog, isInitialLoad]);

  const fetchUserSettings = async () => {
    try {
      const url = `${BACKEND_URL}/user-settings/${username}`;
      console.log('Fetching user settings from URL:', url);
      const response = await fetch(url);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        updateUserSettings(data);
        setUserCurrency(data.currency || 'USD');
      } else {
        const text = await response.text();
        console.error("Received non-JSON response:", text);
        throw new Error(`Expected JSON, but received ${contentType}: ${text.substring(0, 100)}...`);
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setError(`Failed to fetch user settings: ${error.message}`);
      updateUserSettings(DEFAULT_USER_SETTINGS);
      setUserCurrency('USD');
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserSettings = (settings) => {
    setUserSettings(settings);

    let games = [];
    if (Array.isArray(settings.enabledGames)) {
      games = settings.enabledGames;
    } else if (typeof settings.enabledGames === 'string') {
      games = settings.enabledGames.split(',').map(game => game.trim());
    } else {
      games = DEFAULT_USER_SETTINGS.enabledGames;
    }
    
    games.sort((a, b) => a.localeCompare(b));
    setEnabledGames(games);

    gamesCache.set('enabledGames', {
      games: games,
      timestamp: Date.now()
    });

    if (settings.conditions) {
      setAllowedConditions(settings.conditions);
    }

    if (settings.floorPrice) {
      const parsedFloorPrice = parseFloat(settings.floorPrice);
      if (!isNaN(parsedFloorPrice)) {
        setFloorPrice(parsedFloorPrice);
      } else {
        setFloorPrice(0);
      }
    } else {
      setFloorPrice(0);
    }

    if (settings.bulkSettings) {
      setBulkSettings(settings.bulkSettings);
    }

    setExcludedSkus(settings.excludedSku?.map(String) || []);

    const socialSharingContent = (
      <div>
        <div className="social-share-buttons">
          <a href={`https://www.facebook.com/sharer/sharer.php?u=https://buylist.tcgsync.com/${username}`} target="_blank" rel="noopener noreferrer" className="facebook-share"></a>
          <a href={`https://twitter.com/intent/tweet?text=I%20just%20sold%20my%20cards%20to%20${username}!&url=https://buylist.tcgsync.com/${username}`} target="_blank" rel="noopener noreferrer" className="twitter-share"></a>
          <a href={`https://www.tiktok.com/upload?url=https://buylist.tcgsync.com/${username}&title=I%20just%20sold%20my%20cards%20to%20${username}!`} target="_blank" rel="noopener noreferrer" className="tiktok-share"></a>
        </div>
      </div>
    );
    setBox1Content(socialSharingContent);
  };

  const calculatePrice = useCallback((card, condition, printing) => {
    console.log(`Calculating price for: ${card.name} (${card.game})`);
    console.log(`Condition: ${condition}, Printing: ${printing}`);
    console.log('Game:', card.gameName);
    console.log('Expansion:', card.expansionName);

    if (!userSettings || !exchangeRates) {
      console.log('User settings or exchange rates not available');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null };
    }

    const relevantSkus = card.skus.filter(sku =>
      sku.langAbbr === 'EN' && sku.printingName === printing
    );

    if (relevantSkus.length === 0) {
      console.log('No relevant SKUs found for the given printing');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: null };
    }

    const cacheKey = `${card.productId}-${condition}-${printing}-${userCurrency}`;
    const cachedPrice = priceCache.get(cacheKey);
    const currentTime = Date.now();

    if (cachedPrice && (currentTime - cachedPrice.timestamp) < 3600000) { // 3600000 ms = 1 hour
      console.log('Returning cached price:', cachedPrice);
      return cachedPrice;
    }

    // Get the specific condition's TCGPlayer price
    const conditionSku = relevantSkus.find(sku => sku.condAbbr === condition);
    const tcgPlayerPrice = conditionSku ? parseFloat(conditionSku.lowPrice) : 0;
    console.log(`TCGPlayer Price for ${condition}: $${tcgPlayerPrice}`);

    // Get NM price for stepped calculation
    const nmSku = relevantSkus.find(sku => sku.condAbbr === 'NM');
    const basePrice = nmSku ? parseFloat(nmSku.lowPrice) : 0;
    console.log(`Base Price (NM): $${basePrice}`);

    if (basePrice === 0) {
      console.log('Invalid base price');
      return { cashPrice: 0, creditPrice: 0, cashPercentage: 0, creditPercentage: 0, isBulk: false, timestamp: currentTime };
    }

    // Calculate stepped price based on condition
    let steppedPrice;
    switch (condition) {
      case 'NM':
        steppedPrice = basePrice;
        break;
      case 'LP':
        steppedPrice = basePrice * 0.8; // 20% cheaper than NM
        break;
      case 'MP':
        steppedPrice = basePrice * 0.64; // 36% cheaper than NM
        break;
      case 'HP':
        steppedPrice = basePrice * 0.512; // 48.8% cheaper than NM
        break;
      case 'DM':
        steppedPrice = basePrice * 0.4096; // 59.04% cheaper than NM
        break;
      default:
        steppedPrice = basePrice;
    }

    console.log(`Stepped Price for ${condition}: $${steppedPrice.toFixed(2)}`);

    // Use the lower of TCGPlayer price or stepped price
    const adjustedBasePrice = tcgPlayerPrice > 0 ? Math.min(tcgPlayerPrice, steppedPrice) : steppedPrice;
    console.log(`Using price: $${adjustedBasePrice.toFixed(2)} (${tcgPlayerPrice > 0 && tcgPlayerPrice < steppedPrice ? 'TCGPlayer' : 'Stepped'})`);

    let cashPercentage = parseFloat(userSettings.defaultCashPercentage || DEFAULT_USER_SETTINGS.defaultCashPercentage) / 100;
    let creditPercentage = parseFloat(userSettings.defaultCreditPercentage || DEFAULT_USER_SETTINGS.defaultCreditPercentage) / 100;

    console.log('Default percentages:', { cashPercentage: cashPercentage * 100, creditPercentage: creditPercentage * 100 });

    // Rest of the function remains the same...
    // Check for expansion-specific rules
    if (userSettings.expansionRules && Array.isArray(userSettings.expansionRules)) {
      console.log('Checking expansion rules for:', card.gameName, card.expansionName);
      console.log('Available expansion rules:', userSettings.expansionRules);
      
      const expansionRule = userSettings.expansionRules.find(rule => 
        rule.game === card.gameName && 
        rule.expansion === card.expansionName
      );

      if (expansionRule) {
        console.log('Found matching expansion rule:', expansionRule);
        
        if (expansionRule.conditions && expansionRule.conditions[condition]) {
          const ruleCondition = expansionRule.conditions[condition];
          console.log('Applying expansion rule condition:', ruleCondition);
          
          if (ruleCondition.cash_percentage && ruleCondition.credit_percentage) {
            cashPercentage = parseFloat(ruleCondition.cash_percentage) / 100;
            creditPercentage = parseFloat(ruleCondition.credit_percentage) / 100;
            console.log(`Applied expansion-specific percentages: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
          } else {
            console.log('Invalid percentages in expansion rule condition, falling back to general pricing rules');
            applyGeneralPricingRules();
          }
        } else {
          console.log('No matching condition in expansion rule, falling back to general pricing rules');
          applyGeneralPricingRules();
        }
      } else {
        console.log('No matching expansion rule found, falling back to general pricing rules');
        applyGeneralPricingRules();
      }
    } else {
      console.log('No expansion rules available, falling back to general pricing rules');
      applyGeneralPricingRules();
    }

    function applyGeneralPricingRules() {
      if (userSettings.pricing_rules && Array.isArray(userSettings.pricing_rules)) {
        console.log('Checking pricing rules:', userSettings.pricing_rules);
        const applicableRule = userSettings.pricing_rules.find(rule => 
          rule.game === card.gameName &&
          adjustedBasePrice >= rule.min_value &&
          adjustedBasePrice <= rule.max_value
        );

        if (applicableRule) {
          cashPercentage = applicableRule.cash_percentage / 100;
          creditPercentage = applicableRule.credit_percentage / 100;
          console.log(`Applied pricing rule: Cash ${cashPercentage * 100}%, Credit ${creditPercentage * 100}%`);
          console.log('Rule details:', applicableRule);
        } else {
          console.log('No applicable pricing rule found, using default percentages');
        }
      } else {
        console.log('No pricing rules available, using default percentages');
      }
    }

    let cashPrice = adjustedBasePrice * cashPercentage;
    let creditPrice = adjustedBasePrice * creditPercentage;

    const floorPrice = parseFloat(userSettings.floorPrice || DEFAULT_USER_SETTINGS.floorPrice);
    console.log('Floor price:', floorPrice);

    // Mark as bulk if EITHER cash OR credit price is below floor price
    const isBulk = cashPrice < floorPrice || creditPrice < floorPrice;

    if (isBulk) {
      cashPrice = 0;
      creditPrice = 0;
      console.log('Item is bulk (either cash or credit price below floor), prices set to 0');
    }

    // Convert prices to user's currency
    const exchangeRate = exchangeRates[userCurrency] || 1;
    cashPrice *= exchangeRate;
    creditPrice *= exchangeRate;

    const result = {
      cashPrice: parseFloat(cashPrice.toFixed(2)),
      creditPrice: parseFloat(creditPrice.toFixed(2)),
      cashPercentage: cashPercentage,
      creditPercentage: creditPercentage,
      isBulk: isBulk,
      timestamp: currentTime,
      currency: userCurrency
    };

    priceCache.set(cacheKey, result);
    console.log(`Final calculated prices for ${card.name}:`, result);
    return result;
  }, [userSettings, exchangeRates, userCurrency]);

  const addToCart = useCallback((card, condition, printing, quantity, basePrice, skuId, isCash) => {
    const { cashPrice, creditPrice, isBulk, timestamp } = calculatePrice(card, condition, printing);
    const newItem = {
      ...card,
      condition,
      printing,
      quantity: Math.min(quantity, 4),
      basePrice,
      cashPrice,
      creditPrice,
      skuId,
      isCash,
      isBulk,
      timestamp,
      cartItemId: Date.now()
    };
    setCartItems(prevItems => [...prevItems, newItem]);
  }, [calculatePrice]);

  const removeFromCart = useCallback((item) => {
    setCartItems(prevItems => prevItems.filter(cartItem => cartItem.cartItemId !== item.cartItemId));
  }, []);

  const updateQuantity = useCallback((item, newQuantity) => {
    setCartItems(prevItems => prevItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, quantity: Math.min(Math.max(newQuantity, 1), 4) }
        : cartItem
    ));
  }, []);

  const togglePaymentType = useCallback((item) => {
    setCartItems(prevItems => prevItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, isCash: !cartItem.isCash }
        : cartItem
    ));
  }, []);

  const startCheckout = useCallback(() => {
    setIsCheckingOut(true);
  }, []);

  const cancelCheckout = useCallback(() => {
    setIsCheckingOut(false);
  }, []);

  const goToMainPage = useCallback(() => {
    setIsCheckingOut(false);
  }, []);

  const completeCheckout = useCallback((checkoutData) => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
    setIsCheckingOut(false);
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleAbbrChange = useCallback((e) => {
    setSearchAbbr(e.target.value);
  }, []);

  const handleNumberChange = useCallback((e) => {
    setSearchNumber(e.target.value);
  }, []);

  const handleSearchSubmit = useCallback(async (e) => {
    e.preventDefault();
    setIsSearchLoading(true);
    setIsInitialLoad(false);
    
    try {
      let translatedTerm = searchTerm;
      if (isTranslationAvailable() && i18n.language !== 'en') {
        translatedTerm = await translateText(searchTerm, i18n.language, 'en');
      } else if (!isTranslationAvailable()) {
        console.warn("Translation is not available. Using original search term.");
      }

      const normalizedNumber = normalizeNumber(searchNumber);
      
      setSearchParams(prev => ({
        ...prev,
        term: translatedTerm,
        abbr: searchAbbr.toLowerCase(),
        number: normalizedNumber,
        page: 1
      }));
    } catch (error) {
      console.error("Error translating search term:", error);
      setSearchParams(prev => ({
        ...prev,
        term: searchTerm,
        abbr: searchAbbr.toLowerCase(),
        number: normalizeNumber(searchNumber),
        page: 1
      }));
    } finally {
      setIsSearchLoading(false);
    }
  }, [searchTerm, searchAbbr, searchNumber, i18n.language]);

  const handleGameChange = useCallback((e) => {
    const newGame = e.target.value;
    setSearchParams(prev => ({
      ...prev,
      game: newGame,
      set: '',
      page: 1
    }));
    setIsInitialLoad(false);

    // Check if we have cached sets for this game
    const cachedSets = setsCache.get(newGame);
    if (cachedSets && (Date.now() - cachedSets.timestamp < 3600000)) { // 3600000 ms = 1 hour
      setExpansionNames(cachedSets.sets);
    } else {
      // If not cached or cache is old, fetch will be triggered by useEffect
    }
  }, []);

  const handleSetChange = useCallback((e) => {
    setSearchParams(prev => ({
      ...prev,
      set: e.target.value,
      page: 1
    }));
    setIsInitialLoad(false);
  }, []);

  const handlePageChange = useCallback((newPage) => {
    console.log('Changing to page:', newPage);
    setSearchParams(prev => ({
      ...prev,
      page: newPage
    }));
    setIsInitialLoad(false);
  }, []);

  const handleLanguageChange = useCallback((lang) => {
    i18n.changeLanguage(lang);
    setShowLanguagePopup(false);
  }, [i18n]);

  const handleImageRecognized = useCallback(async (productIds) => {
    setIsLoading(true);
    setIsInitialLoad(false);
    try {
      console.log("Image recognized, productIds:", productIds);
      const recognizedCards = [];
      for (const productId of productIds) {
        const cardData = await fetchCatalog({ ...searchParams, term: '', productId });
        if (cardData && cardData.items && cardData.items.length > 0) {
          recognizedCards.push(cardData.items[0]);
        }
      }
      setRecognizedCard(recognizedCards.length > 0 ? recognizedCards[0] : null);
      setCards(recognizedCards);
    } catch (error) {
      console.error("Error fetching catalog for recognized images:", error);
      setError(`Failed to fetch catalog for recognized images. Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [fetchCatalog, searchParams]);

  const memoizedCardList = useMemo(() => (
    <CardList
      cards={cards}
      addToCart={addToCart}
      allowedConditions={allowedConditions}
      floorPrice={floorPrice}
      bulkSettings={bulkSettings}
      userSettings={userSettings}
      excludedSkus={excludedSkus}
      calculatePrice={calculatePrice}
      exchangeRates={exchangeRates}
    />
  ), [cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSkus, calculatePrice, exchangeRates]);

  if (error) {
    return <div>{t('Error')}: {error}</div>;
  }

  if (isCheckingOut) {
    return (
      <CheckoutPage
        cartItems={cartItems}
        updateQuantity={updateQuantity}
        removeFromCart={removeFromCart}
        completeCheckout={completeCheckout}
        cancelCheckout={cancelCheckout}
        goToMainPage={goToMainPage}
        userSettings={userSettings}
        username={username}
        calculatePrice={calculatePrice}
        togglePaymentType={togglePaymentType}
      />
    );
  }

  return (
    <ErrorBoundary>
      <div className="app">
        {showLanguagePopup && <LanguagePopup onSelectLanguage={handleLanguageChange} />}
        {showEnquiryPopup && (
          <EnquiryPopup
            onClose={handleCloseEnquiryPopup}
            onContinue={handleContinueEnquiry}
          />
        )}
        <div className="left-column">
          {box1Content && (
            <div className="fair-price-guarantee">
              {box1Content}
            </div>
          )}
          <div className="button-explanations">
            <h4>{t('Button Explanations')}:</h4>
            <div className="explanation-item">
              <span className="explanation-button not-buying">{t('Not Buying')}</span>
              <p>{t('We have reached our desired inventory for this version of the card and are not currently purchasing more.')}</p>
            </div>
            <div className="explanation-item">
              <span className="explanation-button bulk">{t('Bulk Item')}</span>
              <p>{t('We are buying this item, but only as a bulk item. Our bulk items pricing will be applied.')}</p>
            </div>
            <div className="explanation-item">
              <span className="explanation-button cash">{t('Cash')}</span>
              <p>{t('The amount we are offering to purchase the card for cash.')}</p>
            </div>
            <div className="explanation-item">
              <span className="explanation-button credit">{t('Credit')}</span>
              <p>{t('The amount we will apply to your store credit account in exchange for this card.')}</p>
            </div>
          </div>
        </div>
        <div className="main-content">
          <div className="filters">
            <div className="search-and-upload">
              <form onSubmit={handleSearchSubmit} className="search-container">
                <input
                  type="text"
                  placeholder={t('Search by Name')}
                  className="search-input"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <input
                  type="text"
                  placeholder={t('Search by Abbreviation')}
                  className="search-input"
                  value={searchAbbr}
                  onChange={handleAbbrChange}
                />
                <input
                  type="text"
                  placeholder={t('Search by Number')}
                  className="search-input"
                  value={searchNumber}
                  onChange={handleNumberChange}
                />
                <button type="submit" className="search-button">{t('Search')}</button>
                <ImageUpload onImageRecognized={handleImageRecognized} />
              </form>
            </div>
            {!isTranslationAvailable() && (
              <p className="translation-warning">
                {t('Translation is not available. Searches will be performed with the original text.')}
              </p>
            )}
            <select className="filter-select" value={searchParams.game} onChange={handleGameChange}>
              {enabledGames.map((game, index) => (
                <option key={index} value={game}>{game}</option>
              ))}
            </select>
            <select className="filter-select" value={searchParams.set} onChange={handleSetChange}>
              <option value="">{t('All Sets')}</option>
              {expansionNames.map((set, index) => (
                <option key={index} value={set}>{set}</option>
              ))}
            </select>
          </div>
          {searchError && (
            <div className="search-error">
              <p>{searchError}</p>
            </div>
          )}
          {isSearchLoading ? (
            <div className="loading-screen">
              <p>{t('Just fetching you the best prices...')}</p>
            </div>
          ) : isLoading ? (
            <div>{t('Loading')}...</div>
          ) : (
            <>
              {isInitialLoad ? (
                <Infographics />
              ) : recognizedCard ? (
                <div className="recognized-card">
                  <h2>{recognizedCard.name}</h2>
                  <p>{t('Set')}: {recognizedCard.expansionName}</p>
                  <p>{t('Product ID')}: {recognizedCard.productId}</p>
                  {userSettings && memoizedCardList}
                </div>
              ) : (
                userSettings && memoizedCardList
              )}
              {!isInitialLoad && (
                <div className="pagination">
                  <button onClick={() => handlePageChange(searchParams.page - 1)} disabled={searchParams.page === 1}>{t('Previous')}</button>
                  <span>{t('Page')} {searchParams.page} {t('of')} {totalPages}</span>
                  <button onClick={() => handlePageChange(searchParams.page + 1)} disabled={searchParams.page === totalPages}>{t('Next')}</button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="cart-container">
          <Cart
            cartItems={cartItems}
            removeFromCart={removeFromCart}
            checkout={startCheckout}
            updateQuantity={updateQuantity}
            togglePaymentType={togglePaymentType}
            userSettings={userSettings}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

// Debounce function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default App;
