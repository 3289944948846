import React, { useState, useEffect } from 'react';
import Cart from './Cart';
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import OrderCompletionPopup from './OrderCompletionPopup';
import { BACKEND_URL } from '../config';
import './CheckoutPage.css';

const CheckoutPage = ({
  cartItems: initialCartItems,
  updateQuantity,
  removeFromCart,
  completeCheckout,
  cancelCheckout,
  goToMainPage,
  userSettings,
  username,
  calculatePrice,
  togglePaymentType,
  resetApp,
  clearCart
}) => {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [orderNotes, setOrderNotes] = useState('');
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [localCartItems, setLocalCartItems] = useState(initialCartItems);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showCompletionPopup, setShowCompletionPopup] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);

  useEffect(() => {
    // Add event listener for messages from parent window
    window.addEventListener('message', handleMessage);
    
    // Notify parent that the iframe is ready
    window.parent.postMessage({ type: 'IFRAME_READY' }, '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    setLocalCartItems(initialCartItems);
  }, [initialCartItems]);

  const handleMessage = (event) => {
    // Handle messages from parent window
    if (event.data.type === 'UPDATE_CART') {
      setLocalCartItems(event.data.cartItems);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!termsAgreed) {
      setError('You must agree to the terms and conditions.');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    const orderData = {
      customer_name: customerName,
      customer_email: customerEmail,
      termsAgreed,
      orderNotes,
      username,
      line_items: localCartItems.map(item => ({
        productId: item._id,
        skuId: item.skuId,
        name: item.name,
        printType: item.printing,
        condition: item.condition,
        language: 'EN',
        quantity: item.quantity,
        offeredCashPrice: item.cashPrice,
        offeredCreditPrice: item.creditPrice,
        type: item.isCash ? 'Cash' : 'Credit',
        offeredPrice: item.isCash ? item.cashPrice : item.creditPrice,
        lowPrice: item.basePrice,
      })),
    };

    try {
      const response = await fetch(`${BACKEND_URL}/orders`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('Order submitted successfully:', data);
      setOrderCompleted(true);
      setShowCompletionPopup(true);
      
      // Notify parent window of order completion
      window.parent.postMessage({ type: 'ORDER_COMPLETED', orderData: data }, '*');
    } catch (err) {
      console.error('Error submitting order:', err);
      setError(`An error occurred during checkout: ${err.message}. Please try again or contact support if the issue persists.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateQuantity = (item, newQuantity) => {
    const updatedItems = localCartItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, quantity: Math.min(Math.max(newQuantity, 1), 4) }
        : cartItem
    );
    setLocalCartItems(updatedItems);
    updateQuantity(item, newQuantity);
    
    // Notify parent window of cart update
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleRemoveFromCart = (item) => {
    const updatedItems = localCartItems.filter(cartItem => cartItem.cartItemId !== item.cartItemId);
    setLocalCartItems(updatedItems);
    removeFromCart(item);
    
    // Notify parent window of cart update
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const handleTogglePaymentType = (item) => {
    const updatedItems = localCartItems.map(cartItem =>
      cartItem.cartItemId === item.cartItemId
        ? { ...cartItem, isCash: !cartItem.isCash }
        : cartItem
    );
    setLocalCartItems(updatedItems);
    togglePaymentType(item);
    
    // Notify parent window of cart update
    window.parent.postMessage({ type: 'CART_UPDATED', cartItems: updatedItems }, '*');
  };

  const openTermsAndConditions = (e) => {
    e.preventDefault();
    setShowTermsPopup(true);
  };

  const calculateTotal = () => {
    return localCartItems.reduce((total, item) => {
      const price = item.isCash ? item.cashPrice : item.creditPrice;
      return total + (price * item.quantity);
    }, 0);
  };

  const handleCompletionPopupClose = () => {
    setShowCompletionPopup(false);
    // Clear the form and reset the state after closing the popup
    setCustomerName('');
    setCustomerEmail('');
    setOrderNotes('');
    setTermsAgreed(false);
    setLocalCartItems([]);
    setOrderCompleted(false);
    
    // Notify parent window to reset
    window.parent.postMessage({ type: 'RESET_APP' }, '*');
  };

  const renderCheckoutContent = () => {
    if (orderCompleted) {
      return null; // Don't render the checkout form if the order is completed
    }
    return (
      <div className="checkout-page">
        <div className="checkout-header">
          <button onClick={goToMainPage} className="back-button">
            <span className="back-arrow">&#8592;</span> Add More Cards
          </button>
          <h1>Secure Checkout</h1>
        </div>
        {error && <div className="error" role="alert">{error}</div>}
        <div className="checkout-content">
          <div className="order-summary">
            <h2>Order Summary</h2>
            <Cart
              cartItems={localCartItems}
              updateQuantity={handleUpdateQuantity}
              removeFromCart={handleRemoveFromCart}
              togglePaymentType={handleTogglePaymentType}
              hideCheckoutButton={true}
              clearCart={() => {
                setLocalCartItems([]);
                window.parent.postMessage({ type: 'CLEAR_CART' }, '*');
              }}
              userSettings={userSettings}
            />
            <div className="total">
              <strong>Total: ${calculateTotal().toFixed(2)}</strong>
            </div>
          </div>
          <div className="checkout-form">
            <h2>Customer Information</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="customerName">Name</label>
                <input
                  id="customerName"
                  type="text"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  required
                  placeholder="Enter your full name"
                  aria-required="true"
                />
              </div>
              <div className="form-group">
                <label htmlFor="customerEmail">Email</label>
                <input
                  id="customerEmail"
                  type="email"
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  required
                  placeholder="Enter your email address"
                  aria-required="true"
                />
              </div>
              <div className="form-group">
                <label htmlFor="orderNotes">Order Notes (optional)</label>
                <textarea
                  id="orderNotes"
                  value={orderNotes}
                  onChange={(e) => setOrderNotes(e.target.value)}
                  placeholder="Any special instructions or comments?"
                />
              </div>
              <div className="form-group checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={termsAgreed}
                    onChange={(e) => setTermsAgreed(e.target.checked)}
                    required
                    aria-required="true"
                  />
                  <span>I agree to the <a href="#" onClick={openTermsAndConditions}>terms and conditions</a></span>
                </label>
              </div>
              <div className="button-group">
                <button type="submit" className="submit-button" disabled={isSubmitting}>
                  {isSubmitting ? 'Processing...' : 'Submit Order'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="checkout-footer">
          <p>Secure checkout powered by <a href="https://tcgsync.com" target="_blank" rel="noopener noreferrer">TCGSync</a></p>
          <div className="security-badges">
            <span className="security-badge">SSL Secured</span>
            <span className="security-badge">256-bit Encryption</span>
          </div>
        </div>
        {showTermsPopup && (
          <TermsAndConditionsPopup
            termsAndConditions={userSettings.tncS}
            onClose={() => setShowTermsPopup(false)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {showCompletionPopup && (
        <OrderCompletionPopup 
          onClose={handleCompletionPopupClose} 
          isProcessing={isSubmitting}
          orderCompleted={orderCompleted}
          onResetApp={() => window.parent.postMessage({ type: 'RESET_APP' }, '*')}
        />
      )}
      {renderCheckoutContent()}
    </>
  );
};

export default CheckoutPage;
