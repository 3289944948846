import React from 'react';
import './Popup.css';

const Popup = ({ content, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup">
        <div className="popup-content">
          <h2>Bulk Buying Rates</h2>
          <p>We offer competitive rates for bulk purchases. Here are our current bulk buying rates:</p>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <p>These rates apply to cards below our standard buylist threshold. We appreciate your business and look forward to processing your bulk order!</p>
          <button onClick={onClose} className="close-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default Popup;