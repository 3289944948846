import React from 'react';
import './TermsAndConditionsPopup.css';

const TermsAndConditionsPopup = ({ termsAndConditions, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Terms and Conditions</h2>
        <div className="terms-content">
          {termsAndConditions}
        </div>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default TermsAndConditionsPopup;